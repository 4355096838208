import "../style.css";
import "../mobile.css";
import logoCabeca from "./logo_cabeca.PNG";
import Formulario from "../formulario";

const Home = () => {
  return (
    <div className="add-form-container">
      <nav class="navbar-container">
        <ul>
          <li>
            <a href="#inicio" class="navbar-link">
              Inicio
            </a>
          </li>
          <li>
            <a href="#oque" class="navbar-link">
              O que é?
            </a>
          </li>
          <li>
            <a href="#cadastro" class="navbar-link">
              Cadastro
            </a>
          </li>
          <li>
            <a href="#questionario" class="navbar-link">
              Questionário
            </a>
          </li>
        </ul>
      </nav>

      <div id="inicio" class="showcase-container">
        <h1>i9Key - DISC</h1>
        <p>Vamos Começar?</p>
      </div>

      <div class="section-container">
        <div class="section-content">
          <h2 class="section-title">O que é?</h2>

          <div id="oque" class="about-me-content">
            <img src={logoCabeca} alt="logo_cabeca" />
            <p>
              É uma ferramenta de Mapeamento de Perfil, com base no DISC que
              representa quatro perfis fundamentais no comportamento humano:
              Dominância, Influência, Estabilidade e Conformidade, baseadas nos
              conceitos da teoria de Willian Marston. O propósito do DISC é a
              compreensão dos diferentes padrões comportamentais dos individuos
              em diferentes ambientes.Todos os perfis do DISC tem suas
              potencialidades e pontos de atenção. Para realizar este teste faça
              o seu cadastro de identificação e responda as questões que estão
              divididas em blocos, responda a cada Bloco de Questões da forma em
              que mais se identifique com suas características, não existe
              respostas certas ou erradas. Responda todas as questões pensando
              em suas características pessoais. Lembre-se que cada questão de um
              a quatro pode ser escolhida somente uma vez em cada bloco. Este
              teste é utilizado para autoconhecimento e também como ferramenta
              em processos seletivos, neste caso será selecionado o perfil que
              obter maior aderência ao perfil comportamental pré estabelecido
              pelo solicitante da vaga. Por isso volto a afirmar não existe
              certo ou errado e sim a aderência a solicitação. Acreditamos no
              real potencial de cada ser humano, em sua singularidade. Para nos
              a felicidade e o sucesso acontecem através do autoconhecimento.
              Agradecemos a sua participação!
            </p>
          </div>
        </div>
      </div>

      <div class="section-container">
        <div class="section-content">
          <h2 class="section-title">Cadastro</h2>
        </div>

        <Formulario />
      </div>
    </div>
  );
};

export default Home;
