import "../style.css";
import "../mobile.css";

const Final = () => {
  return (
    <div className="add-form-container">
      <nav class="navbar-container"></nav>

      <div class="showcase-container">
        <h1>i9Key - DISC</h1>
        <p>
          Questionário enviar com sucesso, entraremos em contato para fazer a
          devolutiva do seu DISC.
        </p>
      </div>
    </div>
  );
};

export default Final;
