import { useForm } from "react-hook-form";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { isEmail } from "validator";
import "./index.css";

// Função para validar CPF
const validarCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  let soma = 0,
    resto;
  for (let i = 1; i <= 9; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  return resto === parseInt(cpf.substring(10, 11));
};

const Formulario = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const Navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [items2, setItems2] = useState([]);

  const selectsRef = useRef([]);
  const selectsRef2 = useRef([]);

  const [selectedColors, setSelectedColors] = useState({}); // Estado para armazenar as cores dos selects

  const [comboData, setComboData] = useState([]); // Estado para armazenar os dados da API
  const [selectedValue, setSelectedValue] = useState("");

  const [loading, setLoading] = useState(false); // Estado para o carregamento
  const [successMessage, setSuccessMessage] = useState("");

  const fetchItems = async () => {
    try {
      const { data } = await axios.get("https://api.i9keydiscapi.com.br/disc5");

      /*       const { data } = await axios.get("http://192.168.1.106:9001/disc5", {
        auth: { username: "i9KeyD1sC2024*@_", password: "_iD1sciKey2024@#" },
      }); */

      setItems(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItems2 = async () => {
    try {
      const { data } = await axios.get("https://api.i9keydiscapi.com.br/disc6");
      /*       const { data } = await axios.get("http://192.168.1.106:9001/disc6", {
        auth: { username: "i9KeyD1sC2024*@_", password: "_iD1sciKey2024@#" },
      }); */
      setItems2(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchComboData = async () => {
    try {
      const { data } = await axios.get(
        "https://api.i9keydiscapi.com.br/lista/empresa"
      );
      /*       const { data } = await axios.get(
        "http://192.168.1.106:9001/lista/empresa",
        { auth: { username: "i9KeyD1sC2024*@_", password: "_iD1sciKey2024@#" } }
      ); */
      setComboData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchItems();
    fetchItems2();
    fetchComboData();
  }, []);

  const handleSelectChange = (index, listType) => (event) => {
    const selectedValue = event.target.value;

    // Define a cor com base na seleção
    const newColor = selectedValue === "0" ? "white" : "#d1e7dd"; // Exemplo: branco para "não selecionado", verde claro para selecionado

    setSelectedColors((prevColors) => ({
      ...prevColors,
      [`${listType}-${index}`]: newColor, // Armazena a cor do select
    }));
  };

  const onSubmit = async (data) => {
    let allValid = true;

    // Verificar selects da primeira lista
    items.forEach((item, index) => {
      const selectedValue = selectsRef.current[index]?.value;
      if (!selectedValue || selectedValue === "0") {
        alert(`Por favor, selecione uma opção válida para: ${item.descricao}`);
        allValid = false;
      }
    });

    // Verificar selects da segunda lista
    items2.forEach((item2, index) => {
      const selectedValue = selectsRef2.current[index]?.value;
      if (!selectedValue || selectedValue === "0") {
        alert(`Por favor, selecione uma opção válida para: ${item2.descricao}`);
        allValid = false;
      }
    });

    if (!allValid) return;

    // Montar arrays com os valores selecionados
    const selectedItems = items.map((item, index) => ({
      id: item.cod,
      descricao: item.descricao,
      selectedValue: selectsRef.current[index]?.value || "Selecione uma opção",
    }));

    const selectedItems2 = items2.map((item2, index) => ({
      id: item2.cod,
      descricao: item2.descricao,
      selectedValue: selectsRef2.current[index]?.value || "Selecione uma opção",
    }));

    const formData = {
      name: data.name,
      email: data.email,
      CPF: data.CPF,
      date: data.date,
      empresa: data.empresa,
      celular: data.numero,
      cidade: data.cidade,
      uf: data.uf,
      cargo: data.cargo,
      cargopapel: data.cargopapel,
      pergunta: data.pergunta,
      items: selectedItems,
      items2: selectedItems2,
    };

    setLoading(true); // Inicia o estado de carregamento
    setSuccessMessage(""); // Limpa mensagens anteriores

    try {
      /*       const response = await axios.post(
        "http://192.168.1.106:9001//insere/formulario",
        {
          auth: { username: "i9KeyD1sC2024*@_", password: "_iD1sciKey2024@#" },
        },
        formData
      ); */
      const response = await axios.post(
        "https://api.i9keydiscapi.com.br/insere/formulario",
        formData
      );
      console.log("Resposta da API:", response.data);
      setSuccessMessage("Dados enviados com sucesso!"); // Exibe mensagem de sucesso
      Navigate("/concluido");
    } catch (error) {
      console.error("Erro ao enviar os dados", error);
    } finally {
      setLoading(false); // Finaliza o estado de carregamento
    }
  };

  return (
    <div id="cadastro" className="add-form-container">
      <div className="section-content">
        <label>Name</label>
        <input
          className={errors?.name && "input-error"}
          type="text"
          placeholder="Informe seu nome"
          {...register("name", { required: true })}
        />
        {errors?.name && <p className="error-message">Nome é obrigatório.</p>}
      </div>

      <div className="section-content">
        <label>E-mail</label>
        <input
          className={errors?.email && "input-error"}
          type="email"
          placeholder="Informe seu e-mail"
          {...register("email", {
            required: true,
            validate: (value) => isEmail(value),
          })}
        />
        {errors?.email && (
          <p className="error-message">
            {errors.email.type === "required"
              ? "Email é obrigatório."
              : "Email é invalido."}
          </p>
        )}
      </div>

      <div className="section-content">
        <label>CPF</label>
        <input
          className={errors?.CPF && "input-error"}
          type="text"
          placeholder="Informe seu CPF"
          {...register("CPF", {
            required: true,
            validate: validarCPF,
          })}
        />
        {errors?.CPF && (
          <p className="error-message">
            {errors.CPF.type === "required"
              ? "CPF é obrigatório."
              : "CPF inválido."}
          </p>
        )}
      </div>

      <div className="section-content">
        <label>Data de Nascimento</label>
        <input
          className={errors?.nascimento && "input-error"}
          type="date"
          placeholder="Informe sua data de nascimento"
          {...register("date", { required: true })}
        />
        {errors?.data?.type === "required" && (
          <p className="error-message">Data de nascimento é obrigatório.</p>
        )}
      </div>

      <div className="section-content">
        <label>Celular</label>
        <input
          className={errors?.celular && "input-error"}
          type="text"
          placeholder="Informe seu número de celular"
          {...register("numero", { required: true })}
        />
        {errors?.celular?.type === "required" && (
          <p className="error-message">Celular é obrigatório.</p>
        )}
      </div>

      <div className="form-group">
        <label>Selecione a Empresa Solicitante</label>
        <select
          className={errors?.empresa && "input-error"}
          defaultValue="0"
          {...register("empresa", { validate: (value) => value !== "0" })}
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
        >
          <option value="0">Selecio a sua Empresa...</option>
          {comboData.map((item) => (
            <option key={item.cod} value={item.cod}>
              {item.empresa} {/* Ajuste conforme a estrutura dos dados */}
            </option>
          ))}
        </select>

        {errors?.empresa?.type === "validate" && (
          <p className="error-message">Empresa é obrigatório.</p>
        )}
      </div>

      <div className="section-content">
        <label>Cidade</label>
        <input
          className={errors?.cidade && "input-error"}
          type="text"
          placeholder="Informe sua cidade"
          {...register("cidade", { required: true })}
        />
        {errors?.cidade?.type === "required" && (
          <p className="error-message">Cidade é obrigatório.</p>
        )}
      </div>

      <div className="form-group">
        <label>UF</label>
        <select
          className={errors?.uf && "input-error"}
          defaultValue="0"
          {...register("uf", { validate: (value) => value !== "0" })}
        >
          <option value="0">Selecio a sua UF...</option>
          <option value="AL">AL</option>
          <option value="AP">AP</option>
          <option value="AM">AM</option>
          <option value="BA">BA</option>
          <option value="CE">CE</option>
          <option value="DF">DF</option>
          <option value="ES">ES</option>
          <option value="GO">GO</option>
          <option value="MA">MA</option>
          <option value="MT">MT</option>
          <option value="MS">MS</option>
          <option value="MG">MG</option>
          <option value="PA">PA</option>
          <option value="PB">PB</option>
          <option value="PR">PR</option>
          <option value="PE">PE</option>
          <option value="PI">PI</option>
          <option value="RJ">RJ</option>
          <option value="RN">RN</option>
          <option value="RS">RS</option>
          <option value="RO">RO</option>
          <option value="RR">RR</option>
          <option value="SC">SC</option>
          <option selected value="SP">
            SP
          </option>
          <option value="SE">SE</option>
          <option value="TO">TO</option>
        </select>

        {errors?.uf?.type === "validate" && (
          <p className="error-message">UF é obrigatório.</p>
        )}
      </div>

      <div className="section-content">
        <label>Informe seu cargo</label>
        <input
          className={errors?.cargo && "input-error"}
          type="text"
          placeholder="Informe seu cargo"
          {...register("cargo", { required: true })}
        />
      </div>

      <div className="section-content">
        <label>O que te conecta a este Cargo/Papel?</label>
        <input
          className={errors?.cargopapel && "input-error"}
          type="text"
          placeholder="O que te conecta a este Cargo/Papel?"
          {...register("cargopapel", { required: true })}
        />
      </div>

      <div className="section-content">
        <label>
          O que você acredita ser o seu maior talento para se destacar neste
          Cargo/Papel?
        </label>
        <input
          className={errors?.pergunta && "input-error"}
          type="text"
          placeholder="O que você acredita ser o seu maior talento para se destacar neste
            Cargo/Papel?"
          {...register("pergunta", { required: true })}
        />
      </div>
      <div id="questionario" class="section-content">
        <h2 class="section-title">Questionário</h2>
      </div>

      <div className="items-container">
        <h3>
          Indique o quanto você se identifica com as características a seguir em
          cada bloco.
        </h3>

        <div className="primary-items">
          <h5>Selecione o que mais te representa</h5>
          <div className="list-items">
            {items.map((item, index) => (
              <div key={item.cod}>
                <p>{item.descricao}</p>
                <select
                  className="custom-select"
                  defaultValue="0"
                  ref={(el) => (selectsRef.current[index] = el)} // Referenciando os selects
                  onChange={handleSelectChange(index, "items")}
                  style={{
                    backgroundColor:
                      selectedColors[`items-${index}`] || "white",
                  }} // Aplicando cor
                >
                  <option value="0">Selecione uma opção</option>
                  <option value="Nada a ver comigo">Nada a ver comigo</option>
                  <option value="Pouco a ver comigo">Pouco a ver comigo</option>
                  <option value="Mais ou menos a ver comigo">
                    Mais ou menos a ver comigo
                  </option>
                  <option value="Muito a ver comigo">Muito a ver comigo</option>
                </select>
              </div>
            ))}
          </div>
        </div>

        <h3>
          Agora indique a seguir em cada bloco as características que seus
          amigos, familiares e colegas de trabalho acreditam que você deve
          desenvolver.
        </h3>

        <div className="secondary-items">
          <h5>Selecione o que mais te representa</h5>
          <div className="list-items">
            {items2.map((item2, index) => (
              <div key={item2.cod}>
                <p>{item2.descricao}</p>
                <select
                  className="custom-select"
                  defaultValue="0"
                  ref={(el) => (selectsRef2.current[index] = el)} // Referenciando os selects
                  onChange={handleSelectChange(index, "items2")}
                  style={{
                    backgroundColor:
                      selectedColors[`items2-${index}`] || "white",
                  }} // Aplicando cor
                >
                  <option value="0">Selecione uma opção</option>
                  <option value="Não precisa se preocupar com isso">
                    Não precisa se preocupar com isso
                  </option>
                  <option value="Já evidência em seu dia a dia">
                    Já evidência em seu dia a dia
                  </option>
                  <option value="Talvez seja interessante desenvolver">
                    Talvez seja interessante desenvolver
                  </option>
                  <option value="Precisa desenvolver">
                    Precisa desenvolver
                  </option>
                </select>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <button type="submit" disabled={loading}>
              {loading ? (
                <div className="spinner"></div> // Mostra o spinner durante o envio
              ) : (
                "Enviar"
              )}
            </button>
          </div>
        </form>
        {loading && (
          <p>Enviando... Não saida da pagina e nem feche o navegador</p>
        )}{" "}
        {/* Mostra o texto "Carregando" */}
        {successMessage && <p>{successMessage}</p>}{" "}
        {/* Mostra mensagem de sucesso */}
      </div>
    </div>
  );
};

export default Formulario;
